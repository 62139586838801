.Modal {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 11000;
    display: flex;
    width: fit-content;
    overflow-y: auto;
    margin: auto;
    min-width: 320px;
    max-width: 90%;
    min-height: 30%;
    max-height: 90%;
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 0 2px 16px 0 rgba(64, 70, 110, 0.1);
    transition: all 0.3s ease-out;
}

.Modal > * {
    flex: 1 1 100%;
}