.section-title {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.333;
    color: #40466E;
    text-transform: uppercase;
}

.section-card {
    padding: 24px 16px;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(64, 70, 110, 0.16);
}

.section-info .icon {
  width: 32px;
  height: auto;
  float: left;
  margin-right: 16px;
}

.section-info .subtitle {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.6;
    color: #4A4E57;
}

.section-info .text {
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  color: #4A4E57;
}

.section-info .text a {
  color: #9B51E0;
  text-decoration: none;
}

.section-info .text a:hover,
.section-info .text a:focus {
  text-decoration: underline;
}

.section-info .actions {
  margin-top: 30px;
}

.domain-creator {
    margin-top: 10px;
    input {
        width: 100%;
        margin-bottom: 10px;
    }
}

.domain-creator button {
    white-space: nowrap;
}

.settings-domains {
    grid-column: 1/4;
}

.actions-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.settings-header {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.domain-url {
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
    display: flex;
    column-gap: 4px;
    align-items: center;
}

.domain-url:hover {
    color: rgba(0, 0, 0, 0.7);
    transition: all 0.2s;
}

.domain-url .icon {
    height: 14px;
    width: auto !important;
}

.status {
    padding: 5px 0px 20px;
}

.status.failed {
    color: #dc483a;
}

.status-icon {
    height: 18px;
    width: auto;
    fill: #0071f5;
}

.status.failed .status-icon {
    fill: #dc483a;
}

.status p {
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.hint {
    font-size: 14px;
    color: #666666;
}

.hint a {
    color: #9B51E0;
    margin-left: 4px;
    text-decoration: none;
}

.hint a:hover {
    text-decoration: underline;
    transition: all 0.2s;
}

.delete-alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-alert button {
    margin-left: 8px;
    padding: 4px;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.2s linear;
}

.delete-alert button:hover,
.delete-alert button:focus {
    background-color: rgba(255, 255, 255, 0.05);
}

@media (min-width: 768px) {
    .domain-creator {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }

    .domain-creator input {
        margin-bottom: 0;
    }
}

@media (min-width: 1024px) {
    .section-title {
        margin-bottom: 16px;
    }

    .section-card {
        padding: 24px 32px;
    }
    
    .section-info {
        display: grid;
        grid-template-columns: auto minmax(0, 1fr) auto;
        column-gap: 24px;
    }

    .section-info  {
        row-gap: 28px;
    }

    .section-info .icon {
        width: 64px;
        margin-left: 0;
        float: none;
        align-self: center;
    }

    .section-info .details {
        grid-column: 2/4;
    }

    .section-info .actions {
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        column-gap: 24px;
        grid-column: 2/4;
        margin-top: 0;
        align-items: flex-start;
    }
}