.card__content {
    margin-top: 10px;
    border: 1px solid #dadde1;
    cursor: pointer;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow: hidden;
    font-size: 14px;
    max-width: 527px;
  }

  .card__content.small {
    flex-direction: row;
  }
  
  .card__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-height: 274px;
  }

  .small .card__image {
    width: 158px;
    height: 158px;
  }
  
  .card__text {
    width: 100%;
    height: 80px;
    border-top: 1px solid #dadde1;
    padding: 10px 12px;
    color: #1d2129;
    background: #f2f3f5;
  }

  .small .card__text {
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .card__link {
    border-collapse: separate;
    color: #606770;
    direction: ltr;
    display: block;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    height: 11px;
    line-height: 11px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: left;
    text-overflow: ellipsis;
    text-transform: uppercase;
    user-select: none;
    white-space: nowrap;
    /* width: 501px; */
    word-wrap: break-word;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    -webkit-font-smoothing: antialiased;
  }

  .card__text_content {
    border-collapse: separate;
    color: #4b4f56;
    direction: ltr;
    display: block;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    height: 46px;
    line-height: 14.4px;
    max-height: 46px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: left;
    user-select: none;
    word-wrap: break-word;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    -webkit-font-smoothing: antialiased;
  }
  .small .card__text_content {
    height: auto;
    max-height: none;
  }
  
  .card__description {
    border-collapse: separate;
    color: #606770;
    direction: ltr;
    display: -webkit-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 18px;
    line-height: 20px;
    margin-top: 3px;
    max-height: 80px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: left;
    text-overflow: ellipsis;
    user-select: none;
    white-space: normal;
    word-break: break-word;
    word-wrap: break-word;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    -webkit-box-orient: vertical;
    -webkit-font-smoothing: antialiased;
    -webkit-line-clamp: 1;
  }

  .small .card__description {
    display: block;
    height: auto;
  }
  
  .card__title {
    border-collapse: separate;
    color: #1d2129;
    cursor: pointer;
    direction: ltr;
    display: inline;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 18px;
    text-align: left;
    text-decoration-color: #1d2129;
    text-decoration-line: none;
    text-decoration-style: solid;
    transition-delay: 0s;
    transition-duration: 0.1s;
    transition-property: color;
    transition-timing-function: ease-in-out;
    user-select: none;
    white-space: normal;
    word-wrap: break-word;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 20px;
  }

  