.wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    min-width: 296px;
    max-width: 296px;
    border-radius: 16px;
    font-family: 'Roboto', sans-serif;
}

.wrapper-left {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 16px 0 rgba(64, 70, 110, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
}

.wrapper-right {
    display: none;
}

.modal-close {
    position: sticky;
    top: 0;
    right: 0;
    display: block;
    width: 40px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    border: none;
    padding: 0;
    margin: -18px -18px 0 auto;
    transform: translate(16px, -6px);
    cursor: pointer;
    outline: none;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.15s ease-in-out;
}

.modal-close:hover,
.modal-close:focus {
    background-color: rgb(64 70 110 / 0.05);
}

.modal-close>img {
    width: 18px;
    height: 18px;
}

.logo {
    margin-bottom: 24px;
}

.logo img {
    height: 28px;
    width: auto;
}

@media (min-width: 768px) {
    .wrapper {
        min-width: 424px;
        max-width: 424px;
    }

    .wrapper-left {
        padding: 24px 32px;
    }

    .modal-close {
        transform: translate(24px, -16px)
    }
}

@media (min-width: 1024px) {
    .wrapper {
        min-width: 744px;
        max-width: 744px;
        grid-template-columns: minmax(0, 5fr) minmax(0, 3fr);
        column-gap: 48px;
    }

    .wrapper-left {
        padding: 32px 40px;
    }

    .wrapper-right {
        display: flex;
        flex-direction: column;
        padding: 36px 24px 36px 0;
    }

    .wrapper-right>.subtitle {
        margin-top: 0;
        margin-bottom: 24px;
        color: #DFE7F1;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 20px;
        text-align: right;
        text-transform: uppercase;
    }

    .wrapper-right>img {
        width: 100%;
        height: auto;
        margin-top: auto;
        margin-bottom: auto;
    }
}

@media (min-width: 1440px) {
    .wrapper {
        min-width: 936px;
        max-width: 936px;
        column-gap: 64px;
    }

    .wrapper-left {
        padding: 48px 60px;
    }

    .wrapper-right {
        padding: 52px 36px 52px 0;
    }

    .wrapper-right>.subtitle {
        margin-bottom: 48px;
        font-size: 14px;
    }

    .modal-close {
        transform: translate(48px, -40px);
    }
}