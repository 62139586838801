.wrapper {
    background-color: #f8faff;
    padding: 4px 12px;
    border-radius: 10px;
    width: 100%;
}

.wrapper table th,
.wrapper table td {
    text-align: start;
    padding: 5px 40px 5px 5px;
    letter-spacing: 2px;
    font-size: 14px;
}

.wrapper table th {
    color: #666666;
    font-weight: 500;
}