.editor-wrapper {
    width: 100%;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
}

.hidden-wrapper {
    display: none;
}

.image-wrapper {
    position: relative;
    margin-bottom: 16px;
    padding-top: calc((100% / 1.9));
}

.image-grabber,
.image-cropper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.image-cropper {
    max-width: 100%;
    max-height: 100%;
    width: max-content;
    margin: auto;
}

.image-cropper>div:first-child {
    height: 100%;
    width: max-content;
    margin: auto;
}

.image-grabber {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    cursor: pointer;
    text-align: center;
    border: 1px dashed #8A8F98;
    border-radius: 4px;
    background-color: rgba(242, 245, 250, 0.4);
}

.image-grabber>img {
    width: 144px;
    height: auto;
}

.image-btn {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
}

.image-caption {
    margin-top: 0;
    margin-bottom: 8px;
}

.image-caption,
.image-grabber>img {
    display: none;
}

.image-note {
    margin-top: 0;
    margin-bottom: 0;
    color: #8A8F98;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 16px;
    text-transform: uppercase;
}

.error {
    color: #dc483a;
    margin-top: 0;
    font-size: 12px;
    margin-bottom: 16px;
    margin-top: 8px;
}

.editor-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
}

.not-editable {
    display: flex;
    justify-content: center;
}

.not-editable img {
    height: 150px;
    max-width: 100%;
    object-fit: contain;
}

.save-btn {
    margin-left: auto;
    height: fit-content;
}

.upload-btn {
    display: inline-flex;
    padding-top: 16px;
    padding-bottom: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
    color: #9B51E0;
    cursor: pointer;
}

.upload-btn>img {
    margin-right: 8px;
}

.select-wrap {
    position: relative;
}

.select-wrap>img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    width: 10px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    pointer-events: none;
}

.select-control {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 12px 42px 12px 16px;
    border: none;
    border-radius: 2px;
    box-shadow: none;
    background-color: #F2F5FA;
    color: #28292C;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    appearance: none;
    outline: none;
    cursor: pointer;
}

.form-group+.form-group {
    margin-top: 16px;
}

@media (min-width: 768px) {

    .image-caption,
    .image-grabber>img {
        display: block;
    }

    .image-grabber>img {
        margin-bottom: 24px;
    }

    .image-grabber {
        justify-content: flex-end;
    }
}