
/* INPUTS */
.root {
  margin: 0 0;
  width: 100%;
}

.title {
  display: inline-block;
  margin-bottom: 8px;
  color: #8A8F98;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
}

.input,
.textarea {
  display: block;
  font-family: inherit;
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 2px;
  background-color: #F2F5FA;
  color: #28292C;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  transition: box-shadow 0.15s ease-in-out;
}

.input:disabled,
.textarea:disabled {
  color: rgba(40, 41, 44, 0.6);
}

input.input::placeholder,
textarea.textarea::placeholder {
  font-size: inherit;
  line-height: inherit;
}

.textarea {
  padding: 5px 2px;
  height: 120px;
  padding-left: 10px;
  resize: none;
}

.input:focus,
.textarea:focus {
  outline: none;
  box-shadow: inset 0 -2px 0  #40466E;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input:focus + .domain {
  box-shadow: inset 0 -2px 0  #40466E;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input::placeholder,
.textarea::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: #cbcbd5;
}

textarea.textarea.error {
  background: rgb(251, 236, 242);
  box-shadow: inset 10px 0 0 rgb(236, 89, 144), inset 0 0 0 1px #dc483a;
}

textarea.textarea.error:focus {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: inset 0 0 0 rgb(236, 89, 144) , inset 0 0 0 1px #dc483a;
}

input.input.error {
  background: rgb(251, 236, 242);
  box-shadow: inset 10px 0 0 rgb(236, 89, 144), inset 0 0 0 1px #dc483a;
}

input.input.error:focus {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: inset 0 0 0 rgb(236, 89, 144) , inset 0 0 0 1px #dc483a;
}

.input-title {
  display: flex;
  justify-content: space-between;
}

input.lower-case {
  text-transform: lowercase;
}