.root, .root .items_wrapper {
    padding-bottom: 16px;
}
.root h3 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.4;
    color: #13293d;
    text-transform: capitalize;
}

.root .items_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
    column-gap: 24px;
    row-gap: 48px;
    margin-left: auto;
    margin-right: auto;
}

.root .empty-history {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
}

.root .empty-history div {
    background-color: #eff2f8;
    padding: 16px;
    border-radius: 50%;
    height: 70px;
    width: 70px;
}

.root .empty-history p {
    color: #4A4E57;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
    text-transform: none;
}

.root .empty-history .btn {
    height: 40px;
}

@media (min-width: 1024px) {
    .root .items_wrapper {
        column-gap: 36px;
        grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    }
    .root, .root .items_wrapper {
        padding-bottom: 24px;
    }
}