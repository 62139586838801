
.type__item {
  cursor: pointer;
  border: none;

  width: 40px;
  height: 40px;
  border-radius: 50%;

  background: #e5e5ea;

  font-size: 26px;
  text-align: center;
  color: #08090a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.type__item.active {
  background: #40466e;
  color: #fff;
}

.type__item:focus {
  outline: none;
}

.facebook.active {
  background-color: #1877F2;
}

.telegram.active {
  background: linear-gradient(203.2deg, #37AEE2 0%, #1E96C8 100%);;
}

.slack.active {
  background-color: #4A154B;
}

.twitter.active {
  background-color: #00ACEE;
}

.viber.active {
  background-color: #7360f2;
}

.pinterest.active {
  background-color: #BD081C;
}

.linkedin.active {
  background-color: #0E76A8;
}

.instagram.active {
  background: linear-gradient(225deg, #405DE6, #5852DB, #833AB4, #C13584, #E1306C, #FD1D1D);;
}

.whatsapp.active {
  background-color: #25D366;
}

@media only screen and (min-width: 400px){
  .type__item {
    width: 40px;
    height: 40px;
  }
}