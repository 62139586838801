.highlighted-accent-3 {
  color: #33C6C7;
}

.settings {
  width: 100%;
  flex: 1 1 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 32px 16px;
  font-family: 'Roboto', sans-serif;
}

.settings>.title {
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 1.25;
  color: #4A4E57;
}

.settings>.subtitle {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.143;
  color: #8A8F98;
  text-transform: uppercase;
}

.content {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 24px;
  margin-top: 28px;
}

.section-title {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 1.333;
  color: #40466E;
  text-transform: uppercase;
}

.section-card {
  padding: 24px 16px;
  border-radius: 16px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(64, 70, 110, 0.16);
}

.settings-details {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 24px;
  align-items: flex-end;
}

.settings-delete .icon,
.settings-domains .icon,
.settings-team .icon {
  width: 32px;
  height: auto;
  float: left;
  margin-right: 16px;
}

.settings-delete .subtitle,
.settings-domains .subtitle {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.6;
  color: #4A4E57;
}

.settings-delete .text,
.settings-domains .text,
.settings-team .text {
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  color: #4A4E57;
}

.settings-delete .text a,
.settings-domains .text a {
  color: #9B51E0;
  text-decoration: none;
}

.settings-delete .text a:hover,
.settings-delete .text a:focus,
.settings-domains .text a:hover,
.settings-domains .text a:focus {
  text-decoration: underline;
}

.settings-delete .actions {
  margin-top: 16px;
}

.settings-domains .actions {
  margin-top: 30px;
}

.domains-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -14px;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: 4px;
}

.copy-token-input {
  max-width: 460px;
}

.settings-team {
  display: grid;
  row-gap: 32px;
  grid-template-areas: 
      "icon header header"
      "table table table"
      "pagination pagination pagination";
  /* grid-template-areas: 
      "icon header header"
      "icon table table"
      "icon pagination pagination"; */
  column-gap: 24px;
}

.settings-team .icon {
  grid-area: icon;
  align-self: flex-start;
}

.team-header {
  display: grid;
  grid-template-columns: auto;
  /* grid-template-columns: auto auto; */
  column-gap: 40px;
  row-gap: 8px;
  grid-area: header;
}

.team-table {
  display: grid;
  row-gap: 16px;
  grid-area: table;
}

.pagination {
  grid-area: pagination;
  justify-self: center;
}

.table-header, .table-row {
  display: grid;
  grid-template-columns: 40% 30% 30%;
  align-items: center;
}

.table-row .email {
  color: #4A4E57;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-row .status {
  color: #33C6C7;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
  padding: 8px;
  border-radius: 24px;
  background-color: #D6F4F4;
  width: fit-content;
  text-transform: uppercase;
}

.table-row .status.deactivated {
  color: #dc483a;
  background-color: #ddd;
}

.table-row .action {
  color: #1890ff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  column-gap: 8px;
}

.table-row .action span {
  cursor: pointer;
}

.table-row .action span:hover {
  color: #9B51E0;
}

.table-header span {
  color: #8A8F98;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-transform: uppercase;
}
  .modal-close {
    position: absolute;
    top: 12px;
    right: 12px;
    display: block;
    width: 18px;
    height: 18px;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    outline: none;
    transition: opacity 0.15s ease-in-out;
}

.modal-close:hover,
.modal-close:focus {
    opacity: 0.5;
}

.modal-close>img {
    width: inherit;
    height: inherit;
}

.invite-modal {
  padding: 40px 64px;
}

.invite-modal-content {
  display: grid;
  row-gap: 24px;
}

.invite-modal-content h3 {
  color: #40466E;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
}

.invite-modal-content .modal-button {
  justify-self: flex-end;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 8px;
  align-items: center;
}

@media (min-width: 768px) {
  .settings {
    max-width: 768px;
  }

  .invite-modal-content {
    min-width: 400px;
  }
  .team-header {
    grid-template-columns: auto auto;
  }
  .settings-team {
    grid-template-areas: 
      "icon header header"
      "icon table table"
      "icon pagination pagination";
  }
}

@media (min-width: 1024px) {
  .settings {
    max-width: 1056px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .settings>.title {
    font-size: 32px;
  }

  .settings>.subtitle {
    font-size: 14px;
  }

  .content {
    margin-top: 40px;
    row-gap: 32px;
  }

  .section-title {
    margin-bottom: 16px;
  }

  .section-card {
    padding: 24px 32px;
  }

  .settings-details {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 32px;
  }

  .resset-pass {
    margin-left: auto;
  }

  .settings-delete,
  .settings-domains {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr) auto;
    column-gap: 24px;
  }

  .settings-delete {
    row-gap: 12px;
  }

  .settings-domains  {
    row-gap: 28px;
  }

  .settings-delete .icon,
  .settings-domains .icon {
    width: 64px;
    margin-left: 0;
    float: none;
    align-self: center;
  }

  .settings-team .icon {
    width: 64px;
    margin-left: 0;
    float: none;
  }

  .settings-domains .details {
    grid-column: 2/4;
  }

  .domains-list {
    margin-bottom: 0;
  }

  .settings-domains .actions {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    column-gap: 24px;
    grid-column: 2/4;
    margin-top: 0;
    align-items: flex-start;
  }

  .settings-delete .actions {
    margin-top: 0;
    align-self: center;
  }
}

@media (min-width: 1440px) {
  .settings {
    padding: 48px 0;
  }
}