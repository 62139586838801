.modal-close {
    position: absolute;
    top: 12px;
    right: 12px;
    display: block;
    width: 18px;
    height: 18px;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    outline: none;
    transition: opacity 0.15s ease-in-out;
}

.modal-close:hover,
.modal-close:focus {
    opacity: 0.5;
}

.modal-close>img {
    width: inherit;
    height: inherit;
}

.billing {
    width: 100%;
    flex: 1 1 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 32px 16px;
    font-family: 'Roboto', sans-serif;
}

.billing>.title {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 1.25;
    color: #4A4E57;
}

.billing>.subtitle {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.143;
    color: #8A8F98;
    text-transform: uppercase;
}

.content {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 24px;
    margin-top: 28px;
}

.section-title {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.333;
    color: #40466E;
    text-transform: uppercase;
}

.toggle-frequency {
    display: grid;
    grid-template-columns: repeat(2, minmax(128px, 240px));
    column-gap: 16px;
    justify-content: flex-start;
}

.toggle-btn {
    position: relative;
    margin: 0;
    border: none;
    padding: 24px 16px;
    border-radius: 16px;
    background-color: #FFFFFF;
    color: #4A4E57;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    text-align: left;
    outline: none;
    cursor: pointer;
    box-shadow: 0 2px 8px 0 rgba(64, 70, 110, 0.16);
    transition: box-shadow 0.2s;
}

.toggle-btn.selected {
    cursor: default;
    box-shadow: 0 0 0 1px #9B51E0, 0 2px 24px 0 rgba(64, 70, 110, 0.16);
}

.toggle-btn:not(.selected):hover,
.toggle-btn:not(.selected):focus {
    box-shadow: 0 2px 24px 0 rgba(64, 70, 110, 0.16);
}

.toggle-btn>.discount {
    position: absolute;
    top: 4px;
    right: 8px;
    text-transform: uppercase;
    color: #9B51E0;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.333;
}

.tariffs-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
    column-gap: 16px;
    row-gap: 16px;
}

.payment-btn {
    min-width: 240px;
    align-items: center;
    justify-content: center;
}

.payment-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 24px 16px;
    color: #4A4E57;
    text-align: center;
}

.payment-modal > img {
    margin-bottom: 24px;
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
}

.payment-modal > h4 {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.2;
    text-transform: uppercase;
}

.payment-modal > p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
}

.payment-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: auto;
    opacity: 0.5;
}

.payment-loading:after {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #33c6c7 transparent #33c6c7 transparent;
    animation: payment-loading-ring 1.2s linear infinite;
    will-change: transform;
}

.text {
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  color: #4A4E57;
}

span.error_message {
    display: inline-block;
    color: #dc483a;
    font-size: 12px;
    line-height: 1.2;
    margin-top: 4px;
    text-transform: none;
}

.error_message::before {
    display: inline;
    content: "⚠ ";
    vertical-align: middle;
}

footer {
    text-align: center;
    padding: 16px;
    margin-top: 24px;
    border-top: 1px solid #DFE7F1;
    color: #4A4E57;
    justify-content: center;
    display: flex;
    font-size: 14px;
}

footer div {
    max-width: 500px;
}

@keyframes payment-loading-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media (min-width: 768px) {
    .billing {
        max-width: 768px;
    }

    .toggle-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .toggle-btn>.discount {
        position: static;
    }
}


@media (min-width: 1024px) {
    .billing {
        max-width: 1056px;
        padding-left: 32px;
        padding-right: 32px;
    }

    .billing>.title {
        font-size: 32px;
    }

    .billing>.subtitle {
        font-size: 14px;
    }

    .content {
        margin-top: 40px;
        row-gap: 32px;
    }

    .section-title {
        margin-bottom: 16px;
    }

    .toggle-frequency,
    .tariffs-wrapper {
        column-gap: 24px;
    }

    .toggle-btn {
        padding: 16px 24px;
        font-size: 20px;
        line-height: 1.6;
    }
}

@media (min-width: 1440px) {
    .billing {
        padding: 48px 0;
    }
}

.FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    background-color: #7795f8;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #829fff;
    border-radius: 4px;
  }
  
  .FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #819efc;
  }