.box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: 'Roboto', sans-serif;
  border: none;
  margin: 0;
  padding: 24px 16px;
  border-radius: 16px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(64, 70, 110, 0.16);
  text-align: left;
  cursor: pointer;
  outline: none;
  transition: box-shadow 0.2s linear;
}

.box.selected {
  cursor: default;
  box-shadow: 0 0 0 1px #9B51E0, 0 2px 24px 0 rgba(64, 70, 110, 0.16);
}

.box:disabled {
  cursor: default;
  background-color: #F8FAFF;
}

.box:not(.selected):not(:disabled):hover,
.box:not(.selected):not(:disabled):focus {
  box-shadow: 0 2px 24px 0 rgba(64, 70, 110, 0.16);
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-family: 'PT Mono', monospace;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.2;
  color: #9B51E0;
  text-transform: uppercase;
}

.soon {
  display: inline-block;
  margin-bottom: 2px;
  padding: 0 13px;
  font-family: 'PT Mono', monospace;
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2;
  border: 1px solid #DFE0E2;
  border-radius: 24px;
  background-color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
}

.features {
  margin: 0 0 24px;
  padding-left: 20px;
  color: #9B51E0;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.25;
}

.features>li+li {
  margin-top: 8px;
}

.features>li>span {
  color: #4A4E57;
}

.fetures-bonuced>li:first-child {
  margin-bottom: 36px;
  font-weight: 500;
}

.price {
  margin-top: auto;
  color: #9B51E0;
}

.price-sign {
  font-size: 24px;
  line-height: 1.25;
}

.price-val {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.167;
}

.price-period {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 1.2;
}

@media (min-width: 1024px) {
  .box {
    padding: 32px;
  }

  .title {
    font-size: 35px;
  }

  .price-sign {
    font-size: 32px;
  }

  .price-val {
    font-size: 48px;
  }

  .price-period {
    font-size: 20px;
  }
}

@media (min-width: 1440px) {
  .soon {
    font-size: 12px;
  }
}