code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;

  background-color: #f8faff;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.react-select__placeholder {
  position: relative;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  color: #40466E;
  letter-spacing: 1px;
}

.react-select__control::before {
  content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -1px;
    display: block;
    height: 2px;
    width: 60%;
    background-color: #9B51E0;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.15s linear;
}

.react-select__control:hover::before,
.react-select__control--menu-is-open::before {
    transform: scaleX(1);
}
