.skeleton {
  width: 100%;
  height: auto;
}

.root {
  /* padding: 24px 48px; */
  padding: 12px 12px;
  display: grid;
  row-gap: 24px;
  flex-direction: column;
}

.root .filters {
  display: grid; 
  /* grid-template-columns: 2fr 1fr auto;  */
  grid-template-columns: auto; 
  column-gap: 8px;
  row-gap: 8px;
}

.root .filters .date-picker>div {
  border: 1px solid #DFE0E2;
  border-radius: 32px;
  background-color: #FFFFFF;
  padding: 1px 16px;
  line-height: normal;
  font-family: "Roboto";
  font-size: 14px;
  letter-spacing: 0.5px;
}

.root .filters .date-picker>div svg {
  stroke: #8A8F98;
}

.NameInput {
  border: 1px solid #DFE0E2;
  border-radius: 32px;
  background-color: #FFFFFF;
  padding: 10px 24px;
  line-height: normal;
  font-family: "Roboto";
  font-size: 14px;
  letter-spacing: 0.5px;
}

input.NameInput:focus {
  outline: none;
}

.content-table {
  display: grid;
  grid-template-rows: auto;
  /* row-gap: 12px; */
  row-gap: 8px;
}

.content-table .table-header {
  display: grid;
  /* grid-template-columns: auto 1.5fr 140px 2fr 1fr 1fr 1fr 1fr; */
  grid-template-columns: auto 14% 17% 18% 11% 10% 10% 10%;
  /* padding: 0 24px; */
  padding: 0 8px;
  /* column-gap: 48px; */
  column-gap: 1%;
  color: #8A8F98;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-transform: uppercase;
}

.content-table .table-header span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.created, .destination {
  line-break: anywhere;
} 

.total, .day, .week, .month {
  text-align: center;
}

@media (min-width: 650px) {
  .root .filters {
    grid-template-columns: 2fr 1fr auto; 
  }
}

@media (min-width: 768px) {
  .root {
    padding: 24px 48px;
  }
  .content-table .table-header {
    /* grid-template-columns: auto 1.5fr 140px 2fr 1fr 1fr 1fr 1fr; */
    grid-template-columns: auto 14% 17% 18% 11% 10% 10% 10%;
    padding: 0 24px;
    /* padding: 0 8px; */
    /* column-gap: 48px; */
    column-gap: 1%;
  }
}

@media (min-width: 860px) {
  .root .filters {
    grid-template-columns: 2fr 1fr auto auto; 
  }
}