.title {
    margin-bottom: 8px;
    font-size: 20px;
    text-transform: initial;
}

.title, .subtitle {
    color: #40466E;
    line-height: 1.167;
    font-weight: 900;
}

.title small {
    display: inline-block;
    font-size: 0.75em;
    line-height: 1.05;
}

.actions-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 10px;
    margin-top: 50px;
}

.save-template-btn {
    background: none;
    padding: 0;
    border: none;
    color: #414bb2;
    font-weight: 500;
}

.save-template-btn:hover {
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.content a:hover {
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
}

p {
    color: #8A8F98;
}

@media (min-width: 768px) {
    .title {
        font-size: 28px;
    }
}

@media (min-width: 1024px) {
    .title {
        font-size: 36px;
    }
}

@media (min-width: 1440px) {
    .title {
        font-size: 44px;
    }
}