.card-seo-instagram {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 22px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0;
    background-color: #efefef;
    overflow: hidden;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out;
    max-width: 278px;
    min-height: 44px;
}

.card-seo-instagram__image {
    height: 158px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.card-seo-instagram__text {
    padding: 12px 12px;
    color: #262626;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 12px;
    line-height: 14px;
}

.card-seo-instagram__title {
    font-size: 14px;
    line-height: 16px;
    display: inline;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 32px;
    margin-bottom: 4px;
    font-weight: 500;
}

.card-seo-instagram__body {
    color: #8e8e8e;
    white-space: wrap;
    max-height: 48px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-seo-instagram__link {
    font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #000000cc;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 16px;
}
