.input-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.soft-error input {
  background: rgb(251, 236, 242);
  box-shadow: inset 0 0 0 1px #dc483a;
}
.select {
  align-self: center;
  width: auto;
  padding-right: 0;
}

.add-domain {
  color: #9b51e0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  cursor: pointer;
}

.add-domain:hover {
  text-decoration: underline;
}
.description {
  margin-top: 8px;
}
