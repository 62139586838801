.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  padding: 0 15px;
  font-size: 20px;
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #40466E;
  z-index: 1000;
  font-family: 'Pacifico', sans-serif;
  display: flex;
  justify-content: space-between;
}

.logo {
  min-width: 100px;
}

.header_buttons {
  display: flex;
  width: 100%; 
  align-items: center; 
  justify-content: space-between;
}

.buttons_pair {
  display: flex;
  width: 150px;
  align-items: center; 
  justify-content: space-between;
}

.sign_out_wrapper {
  display: flex;
  gap: 10px;
}

button.Button.active {
  border: 1px solid #fff;
  background: #40466E;
  color: #fff;
  width: 70px;
  padding: 10px 0;
}

button.Button.disabled {
  cursor: default;
}


button.Button.active:hover, button.Button.selected {
  background-color: #fff;
  color: #40466E;
}

@media only screen and (min-width: 400px){
  /* .root {
    padding: 24px 32px;
  } */
}

@media only screen and (min-width: 400px){
  .header {
    padding: 0 32px;
  }
}

@media only screen and (min-width: 500px){
  
  button.Button.active {
    width: 100px;
  }
  .header .buttons_pair {
    width: 210px;
  }
}

@media only screen and (min-width: 600px){
  .header {
    padding-left: 48px;
    font-size: 28px;
  }
  .logo {
    min-width: 200px;
  }
}

/* METADATA */

.metadata {
  width: 100%;
  max-width: 400px;
  margin-bottom: 30px;
}

h2 {
  font-size: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  color: #13293d;
  text-transform: capitalize;
}