.wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    min-width: 296px;
    max-width: 296px;
    border-radius: 16px;
    font-family: 'Roboto', sans-serif;
}

.wrapper-left {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 16px 0 rgba(64, 70, 110, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
}

.wrapper-right {
    display: none;
}

.modal-close {
    position: sticky;
    top: 0;
    right: 0;
    display: block;
    width: 40px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    border: none;
    padding: 0;
    margin: -18px -18px 0 auto;
    transform: translate(16px, -6px);
    cursor: pointer;
    outline: none;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.15s ease-in-out;
}

.modal-close:hover,
.modal-close:focus {
    background-color: rgb(64 70 110 / 0.05);
}

.modal-close>img {
    width: 18px;
    height: 18px;
}

.logo {
    margin-bottom: 24px;
}

.logo img {
    height: 28px;
    width: auto;
}

.content {
    margin-top: auto;
    margin-bottom: auto;
}

.content>.title {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 1.167;
    font-weight: 900;
    color: #40466E;
    text-transform: initial;
}

.content>.title small {
    display: inline-block;
    font-size: 0.75em;
    line-height: 1.05;
}

.content>.subtitle {
    color: #8A8F98;
    font-size: 8px;
    line-height: 1.6;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.form {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
}

.form-group+.form-notice {
    margin-top: 8px;
}

.form-group span {
    color: #8A8F98;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
}

.form-group+.form-group,
.form-notice+.form-group {
    margin-top: 12px;
}

.mg-top-16px {
    margin-top: 16px;
}

span.error_message {
    display: inline-block;
    color: #dc483a;
    font-size: 12px;
    line-height: 1.2;
    margin-top: 4px;
    text-transform: none;
}

.error_message::before {
    display: inline;
    content: "⚠ ";
    vertical-align: middle;
}

.btn-accent-1 {
    padding-top: 16px;
    padding-bottom: 16px;
    background: #9B51E0;
    color: #fff;
}

.btn-accent-1:focus,
.btn-accent-1:hover,
.btn-accent-1:active {
    filter: brightness(0.9);
    background: #9B51E0;
    color: #fff;
}

.btn-accent-1:disabled {
    filter: brightness(1);
    color: #888;
    background-color: #e5e5ea;
    cursor: default;
}

.btn-accent-2 {
    padding-top: 13px;
    padding-bottom: 13px;
    background: #fff;
    color: #9B51E0;
    border: 1px solid #DFE7F1;
}

.btn-accent-2 img {
    height: 20px;
}

.btn-accent-2:focus,
.btn-accent-2:hover,
.btn-accent-2:active {
    filter: brightness(0.9);
    background: #fff;
    color: #9B51E0;
}

.btn-accent-2:disabled {
    filter: brightness(1);
    color: #888;
    background-color: #e5e5ea;
    cursor: default;
}

.button-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.button-wrapper .google {
    border: 1px solid #9B51E0;
    background: #fff;
    color: #9B51E0;
}

.button-wrapper .google img {
    margin-right: 8px;
}

.socials-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 210px;
    width: 100%;
}

.tab-switcher {
    display: flex;
    align-self: center;
    margin-top: 16px;
}

.tab-switcher>span,
.tab-switcher>button {
    font-size: 12px;
    line-height: 1.143;
    text-transform: uppercase;
    font-weight: 500;
    text-transform: uppercase;
}

.tab-switcher>span {
    display: block;
    color: #8a8f98;
}

.tab-switcher>button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    color: #9B51E0;
    outline: none;
    cursor: pointer;
}

.tab-switcher>button:hover {
    text-decoration: underline;
}

.notice {
    display: inline-block;
    color: #8A8F98;
    font-size: 8px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.6;
    text-transform: uppercase;
}

.notice>a {
    color: #9B51E0;
    text-decoration: none;
}

.notice>a:hover {
    text-decoration: underline;
}

@media (min-width: 768px) {
    .wrapper {
        min-width: 424px;
        max-width: 424px;
    }

    .wrapper-left {
        padding: 24px 32px;
    }

    .modal-close {
        transform: translate(24px, -16px)
    }

    .content>.title {
        font-size: 32px;
    }

    .content>.subtitle,
    .notice {
        font-size: 10px;
    }
}

@media (min-width: 1024px) {
    .wrapper {
        min-width: 744px;
        max-width: 744px;
        grid-template-columns: minmax(0, 5fr) minmax(0, 3fr);
        column-gap: 48px;
    }

    .wrapper-left {
        padding: 32px 40px;
    }

    .wrapper-right {
        display: flex;
        flex-direction: column;
        padding: 36px 24px 36px 0;
    }

    .wrapper-right>.subtitle {
        margin-top: 0;
        margin-bottom: 24px;
        color: #DFE7F1;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 20px;
        text-align: right;
        text-transform: uppercase;
    }

    .wrapper-right>img {
        width: 100%;
        height: auto;
        margin-top: auto;
        margin-bottom: auto;
    }

    .content>.title {
        font-size: 40px;
    }

    .content>form {
        margin-top: 48px;
    }

    .form-group+.form-notice {
        margin-top: 12px;
    }

    .form-group+.form-group,
    .form-notice+.form-group {
        margin-top: 16px;
    }

    .tab-switcher>span,
    .tab-switcher>button {
        font-size: 14px;
    }
}

@media (min-width: 1440px) {
    .wrapper {
        min-width: 936px;
        max-width: 936px;
        column-gap: 64px;
    }

    .wrapper-left {
        padding: 48px 60px;
    }

    .wrapper-right {
        padding: 52px 36px 52px 0;
    }

    .wrapper-right>.subtitle {
        margin-bottom: 48px;
        font-size: 14px;
    }

    .modal-close {
        transform: translate(48px, -40px);
    }

    .content>.title {
        font-size: 48px;
    }

    .content>form {
        margin-top: 64px;
    }

    .form-group+.form-notice {
        margin-top: 16px;
    }

    .form-group+.form-group,
    .form-notice+.form-group {
        margin-top: 24px;
    }
}


/* .google_login {
    background-color: #D54C3F;
    height: 50px;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    cursor: pointer;
    padding: 0 10px;
}

.google_login i {
    font-size: 28px;
    color: #fff;
    display: flex;
    align-items: center;
}

.google_login span {
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
    margin-left: 10px;
    text-transform: none;
} */