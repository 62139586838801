.root h1 {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  background-color: #0d4d96;
  line-height: 30px;
  padding-left: 50px;
  display: flex;
  align-items: center;
  text-align: center;
}

.root {
  width: 100%;
  color: #545458;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: rgba(115, 128, 156, 0.29) 0px 4px 12px;
  border-radius: 4px;
  grid-template-areas:
    "builder preview";
  justify-items: center;
  grid-template-columns: auto 1fr;
}

.root header {
  grid-area: header;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* METADATA */

.metadata {
  border-radius: 4px;
  height: fit-content;
  overflow-y: auto;
  display: grid;
  gap: 16px;
  top: 0;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto auto 1fr;
}

.sticky_wrapper {
  width: 100%;
  position: relative;
  grid-area: builder;
  padding-top: 16px;
  background-color: #FFFFFF;
  box-shadow: 0 0 4px 0 rgba(64, 70, 110, 0.1);
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}

.information-toast {
  border-radius: 4px;
  background-color: #ffe9dc;
  padding: 8px 16px;
  display: flex;
  gap: 16px;
  max-height: 100%;
  max-width: 280px;
  height: fit-content;
}

.information-toast.hidden {
  display: none;
}

.information-toast img {
  width: 25px;
}

.information-toast span {
  color: #28292C;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.form_wrapper,
.metadata .title-wrapper {
  padding: 0 16px;
}

.form_wrapper .btn {
  margin-top: 16px;
}

.preview_container {
  grid-area: preview;
  background-color: rgb(248, 250, 255);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
}

.preview {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.root h2 {
  color: #4A4E57;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  text-transform: capitalize;
  margin-bottom: 8px;
}

.root .secondary_title {
  color: #8A8F98;
  line-height: 16px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
}

.footer_wrapper {
  display: flex;
  align-items: flex-end;
}

.footer {
  display: flex;
  height: 90px;
  align-items: center;
  border-top: 1px solid #f2f5fa;
  width: 100%;
  padding: 0 16px;
}

.modal-content {
  position: relative;
  padding: 24px 16px;
}

.close-modal {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.15s ease-in-out;
}

.close-modal:hover,
.close-modal:focus {
  background-color: rgb(64 70 110 / 0.05);
}

.close-modal>img {
  width: 18px;
  height: 18px;
}

.modal_caption {
  margin-bottom: 24px;
  color: #40466E;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .modal-content {
    padding: 32px 40px;
  }

  .close-modal {
    right: 8px;
    top: 8px;
  }
}

@media (min-width: 1024px) {
  .root {
    display: grid;
  }

  .sticky_wrapper {
    height: max-content;
    position: sticky;
    top: 72px;
  }

  .preview_container {
    padding: 16px 48px;

  }

  .form_wrapper,
  .footer,
  .metadata .title-wrapper {
    padding: 0 32px;
  }

  .modal-content {
    padding: 40px 56px 32px;
  }

  .close-modal {
    right: 16px;
    top: 16px;
  }
  .metadata {
    height: calc(100vh - 72px - 16px);
  }

  .footer_wrapper {
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
  }
}

@media (min-width: 1200px) {
  .title-wrapper {
    flex-direction: row;
  }

  .information-toast.hidden {
    visibility: hidden;
    display: flex;
  }
}

@media (min-width: 1440px) {
  .root {
    grid-template-columns: 640px 1fr;
  }

  .form_wrapper,
  .footer,
  .metadata .title-wrapper {
    padding: 0 48px;
  }

  .preview_container {
    padding: 48px 80px;
  }

  .modal-content {
    padding: 48px 64px 40px;
  }
}