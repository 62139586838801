.im_message_webpage_wrap {
    margin: 3px 0;
    border-left: 4px solid #1d82cd;
    padding: 3px 6px 2px 6px;
    font: 13px/18px Tahoma, sans-serif, Arial, Helvetica;
    max-width: 380px;
    margin-top: 10px;
    background-color: #ecf2fa;
    border-radius: 5px;
  }

  .im_message_webpage_photo::after {
    content: "";
    display: block; 
    clear: both;
  }

  .im_message_webpage_host {
    font-weight: 600;
    color: #1e82cd;
  }
  
  .im_message_webpage_title {
    font-weight: 600;
  }
  
  .im_message_webpage_title span {
    text-decoration: none;
    background: 0 0;
  }
  
  .im_message_webpage_description {
    margin: 2px 0;
  }
  
  span.im_message_photo_thumb {
    display: block;
    overflow: hidden;
    border-radius: 2px;
    margin-top: 5px;
    width: 100%;
  }
  
  img.im_message_photo_thumb,
  img.im_message_video_thumb {
    overflow: hidden;
    border-radius: 2px;
    object-fit: cover;
    max-height: 260px;
    width: 100%;
  }
  