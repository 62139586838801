.btn-accent-2 {
  background: #FF934F;
  color: #fff;
}

.btn-accent-2:focus,
.btn-accent-2:hover,
.btn-accent-2:active {
  filter: brightness(0.9);
  background: #FF934F;
  color: #fff;
}

.btn-accent-2:disabled {
  filter: brightness(1);
  color: #888;
  background-color: #e5e5ea;
  cursor: default;
}

.box {
  padding: 24px 16px;
  border-radius: 16px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(64, 70, 110, 0.16);
}

.icon {
  width: 32px;
  height: auto;
  float: left;
  margin-right: 16px;
}

.details-heading {
  margin-bottom: 16px;
}

.plan-name {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.6;
  color: #4A4E57;
  text-transform: capitalize;
}

.plan-note {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 1.333;
  color: #FF934F;
  text-transform: uppercase;
}

.details-features {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  color: #4A4E57;
  text-transform: lowercase;
}

.details-features::first-letter {
  text-transform: uppercase;
}

.actions {
  margin-top: 16px;
}

.price {
  margin-top: 0;
  margin-bottom: 8px;
  color: #4A4E57;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
}

@media (min-width: 1024px) {
  .box {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr) auto;
    column-gap: 24px;
    padding: 24px 32px;
  }

  .icon {
    width: 64px;
    margin-left: 0;
    float: none;
    align-self: center;
  }

  .details-heading {
    display: flex;
    align-items: center;
  }

  .plan-note {
    margin-top: 4px;
    margin-left: 12px;
  }

  .actions {
    margin-top: 0;
    text-align: right;
  }

  .actions > button {
    margin-left: auto;
  }
}