.root,
.btn-link {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  cursor: pointer;
  font-family: inherit;
  width: fit-content;
  border: none;
  color: #888;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
  text-decoration: none;
}

.root {
  border-radius: 24px;
  background-color: #e5e5ea;
  padding: 12px 24px;
  transition: filter 0.2s, background-color .2s, box-shadow .2s;
}

.btn-link {
  background-color: transparent;
  padding: 0;
  transition: color 0.15s;
  outline: none;
}

.root:focus {
  outline: none;
}

.root.active {
  background: #40466e;
  color: #fff;
  transition: filter .2s;
}

.root:active {
  color: #fff;
  background-color: #40466E;
  transition: background-color .2s, box-shadow .2s;
}

.root.active:focus,
.root.active:hover {
  filter: brightness(0.9);
}

.root.btn-accent-1 {
  padding-top: 16px;
  padding-bottom: 16px;
  background: #9B51E0;
  color: #fff;
}

.root.btn-accent-1:focus,
.root.btn-accent-1:hover,
.root.btn-accent-1:active {
  filter: brightness(0.9);
  background: #9B51E0;
  color: #fff;
}

.root.btn-accent-1:disabled {
  filter: brightness(1);
  color: #888;
  background-color: #e5e5ea;
  cursor: default;
}

.root.btn-sm {
  padding: 12px 16px;
  font-size: 12px;
  line-height: 16px;
}

.btn-link.btn-accent-1 {
  color: #9B51E0;
}

.btn-link.btn-accent-1:focus,
.btn-link.btn-accent-1:hover,
.btn-link.btn-accent-1:active {
  color: #753cab;
}

.btn-link.btn-accent-2 {
  color: #FF934F;
}

.btn-link.btn-accent-2:focus,
.btn-link.btn-accent-2:hover,
.btn-link.btn-accent-2:active {
  color: #dc7e42;
}

.btn-link:disabled {
  opacity: 0.7;
}

.btn-icon-only {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-family: 'Roboto', sans-serif;
  background-color: transparent;
  border: none;
  outline: none;
  transition: background-color .2s;
}

.btn-icon-only>svg {
  width: 100%;
  height: auto;
  fill: #8A8F98;
  transition: fill .2s;
}

.btn-icon-only:hover,
.btn-icon-only:focus {
  background-color: rgb(155 81 224 / 0.05);
}

.btn-icon-only:hover > svg,
.btn-icon-only:focus > svg {
  fill: #9B51E0;
}