/* OUTPUT LIST */
  .root {
    width: 100%;
  }

  .title {
    display: inline-block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.43;
    color: #545458;
  }

  .output__list {
    padding-top: 20px;
  }

  .output_url {
    display: block;
    margin: 15px 0;
    padding: 8px 2px 0;

    width: 250px;
    height: 30px;

    border: 1px solid #e5e5ea;
    border-radius: 4px;

    font-size: 12px;
    line-height: 14px;
    color: #08090a;
  }

  .output {
    display: flex;
    width: 100%;
    border: 1px solid #DFE0E2;
    border-radius: 32px;
    background-color: #FFFFFF;
    padding: 8px;
  }

  blockquote {
    margin: 0;
    position: relative;
    border: 1px solid #e5e5ea;
    border-left: none;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-left: 24px;
    font-size: 14px;
    line-height: 1.25;
    color: #252525;
    word-break: break-all;
    display: flex;
    flex-direction: column;
  }

  blockquote input {
    /* margin-bottom: 4px; */
    width: 100%;
    color: #28292C;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
  }

  blockquote label.description {
    color: #8A8F98;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 16px;
    text-transform: uppercase;
  }

  .root .button {
    background-color: #9B51E0;
  }

  .copy_btn:active {
    color: #fff;
    background-color: #1d3249;
    transition: background-color .2s,box-shadow .2s;
  }

  .output blockquote {
    border: none;
    flex-grow: 1;
  }
