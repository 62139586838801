.card-seo-linkedin {
    cursor: pointer;
    position: relative;
    display: block;
    background-color: #ffffff;
    overflow: hidden;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.card-seo-linkedin__content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.card-seo-linkedin__image {
    height: 288px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #dadde1;
}

.card-seo-linkedin__text {
    padding: 8px 16px;
    color: #1d2129;
    border: 1px solid #e6e9ec;
    border-top: none;
    background: #f3f6f8;
    display: flex;
    flex-grow: 1;
}

.card-seo-linkedin__title {
    display: block;
    direction: ltr;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0,0,0,0.9);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 40px;
}

.card-seo-linkedin__link, .card-seo-linkedin__description {
    font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: rgba(0,0,0,0.6);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 16px;
}

@media only screen and (min-width: 770px){
    .card-seo-linkedin__image {
        max-width: 527px;
    }
    .card-seo-linkedin {
        max-width: 527px;
    }
}