.card_seo_twitter {
    cursor: pointer;
    /* position: relative; */
    display: block;
    background-color: #ffffff;
    border-radius: 14px;
    border-style: solid;
    border-color: #E1E8ED;
    border-width: 1px;
    margin-left: 44px;
    margin-top: 10px;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
}

.card_seo_twitter:before {
    content: '';
    position: absolute;
    top: 0;
    left: -48px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #EBF1FA
}

@media only screen and (min-width: 770px) {
    .card_seo_twitter {
        width:506px;
        border-radius: 0.42857em;
        margin-left: 0;
        overflow: hidden
    }

    .card_seo_twitter:before {
        display: none
    }
}

@media only screen and (min-width: 770px) {
    .card_seo_twitter:hover {
        background-color:#F5F8FA;
        border-color: rgba(136,153,166,0.5)
    }
}

.card_seo_twitter__image {
    height: 150px;
    background-size: cover;
    background-color: #E1E8ED;
    background-repeat: no-repeat;
    background-position: center;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 1px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    overflow: hidden
}

@media only screen and (min-width: 770px) {
    .card_seo_twitter__image {
        width:504px;
        height: 252px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        overflow: hidden
    }
}

.card_seo_twitter__text {
    padding: 9px
}

@media only screen and (min-width: 770px) {
    .card_seo_twitter__text {
        padding:0.75em 1em
    }
}

.card_seo_twitter__title {
    display: block;
    margin: 0 0 0.15em;
    font-size: 15px;
    line-height: 18.375px;
    letter-spacing: normal;
    overflow: hidden;
    color: rgb(20, 23, 26);
}

@media only screen and (min-width: 770px) {
    .card_seo_twitter__title {
        white-space:nowrap;
        text-overflow: ellipsis;
        font-size: 1em;
        line-height: 1.3em;
        max-height: 1.3em
    }
}

.card_seo_twitter__description {
    display: none;
    height: 2.6em;
    max-height: 2.6em;
    margin-top: 0.32333em;
    line-height: 1.3em;
    letter-spacing: normal;
    word-wrap: break-word;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: rgb(101, 119, 134);
}

@media only screen and (min-width: 770px) {
    .card_seo_twitter__description {
        display:block;
        display: -webkit-box
    }
}

.card_seo_twitter__link {
    display: block;
    color: #657786;
    text-transform: lowercase;
    line-height: 1.3125;
    letter-spacing: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

@media only screen and (min-width: 770px) {
    .card_seo_twitter__link {
        margin-top:0.32333em;
        color: #8899A6
    }
}
