.wrapper {
    box-shadow: 0 2px 4px 0 rgba(64,70,110,0.1);
}

.root {
    background-color: #fff;
    padding: 16px 8px;
    display: grid;
    align-items: center;
    /* grid-template-columns: auto 1.5fr auto 2fr 1fr 1fr 1fr 1fr; */
  grid-template-columns: auto 14% 17% 18% 11% 10% 10% 10%;
    color: #4A4E57;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    cursor: pointer;
    column-gap: 1%;
    /* column-gap: 48px; */
}

.total, .last24h, .week, .month {
    text-align: center;
}

.actions {
    justify-self: end;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
}

.Pies {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-gap: 16px;
    gap: 16px;
}

.destination, .name, .created {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.icon {
    fill: #8A8F98;
    height: 16px;
    width: auto;
}

.panel {
    display: grid;
    grid-template-columns:  auto;
    column-gap: 48px;
    padding: 24px;
    background-color: #fff;
    border-top: 1px solid #DFE7F1;
    position: relative;
    row-gap: 16px;
}

.card {
    height: fit-content;
    row-gap: 16px;
    display: grid;
}

@media (min-width: 768px) {
    .root {
        padding: 32px 24px;
        /* grid-template-columns: auto 1.5fr auto 2fr 1fr 1fr 1fr 1fr; */
    grid-template-columns: auto 14% 17% 18% 11% 10% 10% 10%;
        column-gap: 1%;
        /* column-gap: 48px; */
    }   
}

@media (min-width: 760px) {
    .panel {
        grid-template-columns: 315px 1fr;
    }
}

@media (min-width: 1140px) {
    .panel {
        grid-template-columns: 315px 1fr 1fr;
    }
}