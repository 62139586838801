.root {
    padding: 16px;
}

@media (min-width: 1024px) {
    .preview-content {
        grid-template-columns: auto auto;
    }
}

@media (min-width: 1440px) {
    .preview-content {
        grid-template-columns: auto auto;
    }
}
