.root {
    padding: 12px 12px;
    display: grid;
    row-gap: 24px;
}

.cards {
    display: grid;
    column-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    max-width: 100%;
}

.card {
    background-color: #fff;
    padding: 8px;
    display: grid;
    column-gap: 8px;
    grid-template-columns: auto;
    box-shadow: 0 2px 8px 0 rgba(155,81,224,0.16);
    /* height: 150px; */
}

.card .image {
    width: 100%;
    /* max-height: 50px; */
    object-fit: cover;
    display: block;
    height: 50px;
}

.card-content {
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    overflow: hidden;
    color: #8A8F98;
    font-size: 12px;
    letter-spacing: 1px;
}

.card-content h2 {
    font-size: 14px;
}

.bars-wrapper {
    display: grid;
    grid-template-columns: auto;
    row-gap: 40px;
    column-gap: 16px;
}

.charts-wrapper {
    padding: 12px 12px;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(155,81,224,0.16);
    position: relative;
}

.Statistic__overlay {
    position: absolute;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    top: 30%;
}

.Statistic__overlay p {
    width: 300px;
    text-align: center;
    padding: 30px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e5e5ea;
    margin: 0;
}

@media (min-width: 768px) {
    .card .image {
        width: 100%;
        max-height: 114px;
        object-fit: cover;
        display: block;
        height: 100%;
    }
    .root {
        padding: 24px 48px;
    }
    .cards {
     column-gap: 24px;
    }
    .card {
    padding: 16px;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr;
    }
    .card-content {
    row-gap: 8px;
    }
    .card-content h2 {
        font-size: 18px;
    }
    .charts-wrapper {
    padding: 24px 40px;
    }
}

@media (min-width: 600px) {
    .bars-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 40px;
    }
}

@media (min-width: 840px) {
    .bars-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 40px;
    }
}