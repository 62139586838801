.modal-content {
    position: relative;
    padding: 24px 16px;
    overflow-x: hidden;
}

.close-modal {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.15s ease-in-out;
}

.close-modal:hover,
.close-modal:focus {
    background-color: rgb(64 70 110 / 0.05);
}

.close-modal>img {
    width: 18px;
    height: 18px;
}

.modal_caption {
    margin-bottom: 32px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
    color: #40466E;
}

.preview-content {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 32px;
}

.period-picker {
    width: 100%;
    margin-bottom: 18px;
}

.period-picker .caption {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-transform: uppercase;
    color: #40466E;
}

@media (min-width: 768px) {
    .modal-content {
        padding: 32px 40px;
    }

    .close-modal {
        right: 8px;
        top: 8px;
    }
}

@media (min-width: 1024px) {
    .modal-content {
        padding: 40px 56px 32px;
    }

    .close-modal {
        right: 16px;
        top: 16px;
    }

    .preview-content {
        display: grid;
        grid-template-columns: minmax(0, 3fr) minmax(0, 4fr);
        column-gap: 48px;
    }
}

@media (min-width: 1440px) {
    .modal-content {
        padding: 48px 64px 40px;
    }

    .preview-content {
        grid-template-columns: minmax(0, 5fr) minmax(0, 6fr);
        column-gap: 88px;
    }

    .period-picker {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .period-picker .caption {
        margin-bottom: 0;
    }
}

.charts_container {
    position: relative;
}

.Bar_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Bar_container .empty_stat_message {
    position: absolute;
    top: 28%;
    padding: 20px;
    background-color: #fff;
    z-index: 100;
    border-radius: 4px;
    border: 1px solid #e5e5ea;
}

.Bar_container .empty_stat_message p {
    margin: 0;
}

.Bar_container p {
    margin-bottom: 0;
}

.Bar_container .Bar_wrapper {
    width: 100%;
    height: 230px;
    overflow: hidden;
}

.Pies_upper_container {
    margin-top: 15px;
}

.Pies_container {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 16px;
}

.Pie_wrapper {
    position: relative;
    width: 170px;
    height: 110px;
}

.Pie_wrapper p {
    position: absolute;
    top: 0;
    left: 80px;
    padding: 0;
    margin: 0;
    color: #40466E;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-transform: uppercase;
}

.Statistic__overlay {
    position: absolute;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    top: 30%;
}

.Statistic__overlay p {
    width: 300px;
    text-align: center;
    padding: 30px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e5e5ea;
    margin: 0;
}

