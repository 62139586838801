.root {
    display: grid;
    gap: 24px
}

.modal_caption {
    margin-bottom: 24px;
    color: #40466E;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
}

.root .btn {
    height: 40px;
}

.close-modal {
    position: absolute;
    right: 24px;
    top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.15s ease-in-out;
  }

  .close-modal:hover,
  .close-modal:focus {
    background-color: rgb(64 70 110 / 0.05);
  }

  .close-modal>img {
    width: 18px;
    height: 18px;
  }

  .error-message {
    display: inline-block;
    color: #dc483a;
    font-size: 12px;
    line-height: 1.2;
    margin-top: 4px;
  }
  