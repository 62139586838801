.root {
    color: rgb(19, 41, 61);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.root .preview_facebook {
    width: 100%;
    border: 1px solid #DADDE1;
    border-radius: 0 0 2px 2px;
    background-color: #F2F3F5;
    display: flex;
    flex-direction: column;
}

.root .preview_facebook .image img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-bottom: 1px solid #DADDE1;
}

.root .preview_facebook .image {
    position: relative;
    height: 220px;
}

.root .preview_facebook .image:hover .overlay_container {
    display: flex;
}

.root .preview_facebook .image .overlay_container {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

}

.root .preview_facebook .image .overlay {
    position: absolute;
    top: 0;
    opacity: 0.8;
    background-color: #40466E;
    width: 100%;
    height: 100%;
}

.root .preview_facebook .image .overlay_container .overlay_button {
    z-index: 100;
    background-color: #fff;
    height: 40px;
    color: #40466E;
}

.root .preview_caption {
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
}

.root .preview_caption>span {
    color: #606770;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    text-transform: uppercase;
}

.root .preview_caption>.title {
    color: #28292C;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
}

.root .preview_caption .secondary {
    color: #606770;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
}

.root .preview_caption p {
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    height: 18px;
    white-space: nowrap;
}

.root .caption .preview-meta {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.root .caption span {
    color: #8A8F98;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 16px;
    text-transform: uppercase;
}

.root .caption h2 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    margin: 0;
    padding: 0;
    color: #4A4E57;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
    text-transform: none;
}

.root .caption .heading-group {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    column-gap: 8px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px;
}

.root .caption .heading-group.editable {
    grid-template-columns: minmax(0, 1fr) auto auto;
}

.root .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 24px;
}

.root .actions .caption-action {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    padding: 8px 12px;
    cursor: pointer;
    color: #4A4E57;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    outline: none;
    border-radius: 24px;
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    transition: color 0.2s linear, background-color 0.2s linear;
    font-family: Roboto;
}

.root .actions .caption-action>.icon {
    fill: #8A8F98;
    height: 16px;
    width: auto;
    transition: fill 0.2s linear;
}

.root .actions .caption-action>span {
    margin-left: 8px;
    margin-top: 2px;
}

.root .actions .caption-action:hover,
.root .actions .caption-action:focus {
    color: #9B51E0;
    background-color: rgb(155 81 224 / 0.05);
}

.root .actions .caption-action:hover>.icon,
.root .actions .caption-action:focus>.icon {
    fill: #9B51E0;
}

.root .caption .target_link {
    display: block;
    color: #8A8F98;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: none;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
}

.root .caption .target_link:hover,
.root .caption .target_link:focus {
    text-decoration: underline;
    color: #9B51E0;
}

.sharing {
    border-top: 1px solid #E5E5EA;
    display: flex;
    flex-direction: column;
}

.sharing .toggle-container {
    display: flex;
    padding: 16px 0;
    align-items: center;
    justify-content: space-between;
}

.toggle-container .toggle-caption {
    color: #4A4E57;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
}

.toggle {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .toggle input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 32px;
    border: 1px solid #DFE0E2;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 2px;
    bottom: 2px;
    background-color: #9B51E0;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #F8FAFF;
  }
  
  /* input:focus + .slider {
    box-shadow: 0 0 1px #9B51E0;
  } */
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

.image_container {
    width: 400px;
    min-width: 200px;
    margin-right: 10px;
}

.image_container img {
    width: 100%;
    object-fit: contain;
    border-radius: 4px;
    max-height: 300px;
}

.content_container {
    max-width: 400px;
    padding: 10px 0;
}

.content_container p {
    margin: 0;
    margin-bottom: 5px
}

.content_container a {
    overflow-wrap: break-word;
}

.title {
    font-weight: bold;
}

.clicks_container {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Statistic_root {
    width: 100%;
}

.Statistic_root p {
    margin: 0;
    margin-top: 0;
    font-weight: bold;
}

p.clicks_counter {
    font-weight: normal;
}

.Statistic_root .Bar_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Statistic_root .Bar_container p {
    margin-bottom: 0;
}

.Statistic_root .Bar_container .Bar_wrapper {
    width: 100%;
    height: 230px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Statistic_root .Pies_upper_container {
    margin-bottom: 5px;
    margin-top: 15px;
}

.Statistic_root .Pies_container {
    display: flex;
    margin-right: 0px;
    justify-content: center;
    margin-bottom: 10px;
}

.Statistic_root .Pies_container.extended {
    width: 67%;
    margin: auto
}

.Statistic_root .Pies_container .Pie_container {
    width: 100%;
    max-width: 240px;
    height: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Statistic_root .Pies_container .Pie_container .Pie_wrapper {
    width: 100%;
    height: calc(100% - 25px);
}

.Statistic_root .Pies_container .Pie_container p {
    margin-top: 5px;
    height: 20px;
}

.Statistic__overlay {
    position: absolute;
    width: 100%;
    height: 580px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.Statistic__overlay p {
    width: 300px;
    text-align: center;
    padding: 30px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e5e5ea;
}

.btn_container {
    display: flex;
    margin-top: 20px;
}

.delete-alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-alert button {
    margin-left: 8px;
    padding: 4px;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.2s linear;
}

.delete-alert button:hover,
.delete-alert button:focus {
    background-color: rgba(255, 255, 255, 0.05);
}

@media only screen and (max-width: 1100px) {
    .root {
        display: grid;
        grid-template-columns: 100%;
    }

    .content_container {
        max-width: 400px;
        width: 100%;
        margin-top: 10px;
    }

    .image_container {
        margin-right: 0;
        max-width: 400px;
        width: 100%;
        height: 100%;
        align-self: center;
        display: flex;
        align-items: center;
    }

    .image_container img {
        border-radius: 4px;
    }

    .clicks_container {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media (min-width: 1024px) {
    .root .caption .preview-meta {
        flex-direction: row;
        justify-content: space-between;
    }

    .root .actions .caption-action + .caption-action {
        margin-left: 8px;
    }
}

@media only screen and (max-width: 720px) {
    .Statistic_root {
        width: 100%;
        min-width: 550px;
    }
}

@media only screen and (max-width: 630px) {
    .Statistic_root {
        width: 100%;
        min-width: 0;
    }

    .Statistic_root .Pies_container {
        flex-direction: column;
        align-items: center;
    }

    .Statistic_root .Pies_container.extended {
        width: 100%;
    }

    .Statistic_root button.period_picker {
        padding: 10px 5px 10px 5px;
        font-size: 12px;
    }
}

button.period_picker {
    border: 1px solid #40466E;
    background: #fff;
    color: #40466E;
    padding: 10px 18px 10px 18px;
}

button.period_picker.selected {
    background: #40466E;
    color: #fff;
}

button.period_picker:focus {
    outline: none;
}