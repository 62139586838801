.dashboard-wrapper {
  width: 100%;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
}

.dashboard-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 24px;
  padding: 0;
  border-bottom: 1px solid #DFE7F1;
}

.nav-item {
  position: relative;
  display: inline-flex;
  padding: 16px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
  color: #8A8F98;
  outline: none;
  text-decoration: none;
  white-space: nowrap;
  transition: color 0.2s linear, box-shadow 0.2s linear;
  align-items: center;
  gap: 8px;
}

.nav-item:hover,
.nav-item:focus {
  color: #40466E;
}

.nav-item.active {
  color: #40466E;
  box-shadow: inset 0 -1px 0 0 #9B51E0, 0 1px 0 0 #9B51E0;
  cursor: default;
}

.nav-item .counter {
  display: inline-flex;
  padding: 2px 8px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
  border-radius: 24px;
  color: #FF934F;
  background-color: #FFE9DC;
  pointer-events: none;
}

.create-new {
  margin-bottom: 16px;
  flex: 1 1 100%;
  order: -1;
}

@media (min-width: 768px) {
  .dashboard-wrapper {
    max-width: 1024px;
  }

  .nav-item {
    padding: 20px 32px;
  }

  .nav-item .soon {
    right: auto;
    left: 100%;
    transform: translate(-25%, 50%);
    font-size: 10px;
  }

  .create-new {
    margin-left: auto;
    flex: none;
    order: 0;
  }

}

.create-new {
  margin-bottom: 16px;
  flex: 1 1 100%;
  order: -1;
}

.dashboard-wrapper .filters {
  display: grid; 
  /* grid-template-columns: 2fr 1fr auto;  */
  grid-template-columns: auto; 
  column-gap: 8px;
  row-gap: 8px;
}

.dashboard-wrapper .filters .date-picker>div {
  border: 1px solid #DFE0E2;
  border-radius: 32px;
  background-color: #FFFFFF;
  padding: 1px 16px;
  line-height: normal;
  font-family: "Roboto";
  font-size: 14px;
  letter-spacing: 0.5px;
}

.dashboard-wrapper .filters .date-picker>div svg {
  stroke: #8A8F98;
}

.NameInput {
  border: 1px solid #DFE0E2;
  border-radius: 32px;
  background-color: #FFFFFF;
  padding: 10px 24px;
  line-height: normal;
  font-family: "Roboto";
  font-size: 14px;
  letter-spacing: 0.5px;
}

input.NameInput:focus {
  outline: none;
}

.select {
  min-width: 200px;
}

@media (min-width: 1074px) {
  .dashboard-wrapper {
    max-width: 1344px;
  }
  .dashboard-nav {
    flex-wrap: nowrap;
  }

  .create-new {
    align-self: center;
    margin-bottom: 0;
    margin-left: auto;
    flex: 0 1 auto;
    order: 0;
  }
}

@media (min-width: 768px) {
  .dashboard-wrapper .filters {
    grid-template-columns: 2fr 1fr auto; 
  }
}

@media (min-width: 860px) {
  .dashboard-wrapper .filters {
    grid-template-columns: 2fr 1fr auto auto; 
  }
}