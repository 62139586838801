.root {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 28px;
}