
.header {
    position: sticky;
    top: 0;
    z-index: 100;
    padding: 16px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(64, 70, 110, 0.1);
}

.header-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 16px;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
}

.header-logo,
.header-link {
    display: block;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    outline: none;
    text-align: left;
    cursor: pointer;
}

.header-link {
    position: relative;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
    color: #40466E;
    cursor: pointer;
    letter-spacing: 1px;
}

.header-link::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -10px;
    display: block;
    height: 2px;
    width: 80%;
    background-color: #9B51E0;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.15s linear;
}

.header-link:hover::before,
.header-link.active::before {
    transform: scaleX(1);
}

.right-side {
    display: flex;
    overflow-y: hidden;
    max-width: 100%;
    flex: 1 1 100%;
    padding-top: 1px;
    padding-bottom: 1px;
    align-items: center;
}

.header-link+.header-link {
    margin-left: 16px;
    height: fit-content;
}

.plan-note {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 16px;
    color: #FF934F;
    text-transform: uppercase;
    text-align: center;
    min-width: 105px;
}

.question {
    width: 28px;
    height: 28px;
    margin-left: 0px;
    cursor: pointer;
}

.menu-select {
    margin-left: 0px;
}

@media (min-width: 768px) {
    .header-content {
        max-width: 1024px;
    }

    .header-link+.header-link {
        margin-left: 24px;
    }

    .builder-link {
        margin-left: auto;
        margin-right: 24px;
    }

    .right-side {
        flex: 0 0 auto;
    }
    .question {
        margin-left: 24px;
    }
    .menu-select {
        margin-left: 24px;
    }
}

@media (min-width: 1024px) {
    .header {
        padding-left: 32px;
        padding-right: 32px;
    }

    .header-content {
        max-width: 1344px;
    }

    .builder-link {
        margin-right: 32px;
    }

    .header-link+.header-link {
        margin-left: 32px;
    }

}

@media (min-width: 1440px) {
    .header-content {
        max-width: none;
    }

    .header {
        padding-left: 48px;
        padding-right: 48px;
    }

    .builder-link {
        margin-right: 48px;
    }

    .header-link+.header-link {
        margin-left: 48px;
    }
}