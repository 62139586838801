.button-group {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 3px;
  border-radius: 16px;
  background-color:rgb(223 231 241 / 0.4);
}

.button-item {
  padding: 7px 16px 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: #40466E;
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s linear, box-shadow 0.2s linear;
}

.button-item.selected {
  border-radius: 24px;
  background-color: #FFFFFF;
  box-shadow: 0 0 4px 0 rgba(64,70,110,0.1);
}

.button-item:hover,
.button-item:focus {
  border-radius: 24px;
  background-color: rgb(255 255 255 / 70%);
}

@media (min-width: 768px) {
  .button-group {
    border-radius: 24px;
  }
}