/* PREVIEW TYPES */
.preview__types {
  position: sticky;
  background-color: rgb(248, 250, 255);
  top: 124px;
  width: 100%;
  padding: 15px 0;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(40px, 40px));
  box-shadow: 0 0 4px 0px rgba(64, 70, 110, 0.1);
  clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
  z-index: 10;
}

.unauthorized {
  top: 72px;
}

@media (min-width: 768px) {
  .preview__types {
    top: 72px;
  }
}


