/* PREVIEW */

.preview__card {
  width: 100%;
  transition: all 0.5s ease;
  padding: 8px;
  margin-top: calc(1.75em - 8px);
  /* max-width: 527px; */
}

.preview__card h4 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;

  font-size: 14px;
  line-height: 17px;
  font-weight: 400;

  color: #666666;
  margin: 0 0 1em;
}

.preview__card h4>span {
  color: #8A8F98;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
}

.preview__line {
  content: "";
  width: 100%;
  height: 1px;
  margin-left: 10px;

  background-color: #e5e5ea;
}

.selected {
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(155, 81, 224, 0.3), 0 2px 24px 0 rgba(64, 70, 110, 0.16);
}

