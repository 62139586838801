span.root {
    display: inline-block;
    color: #dc483a;
    font-size: 12px;
    line-height: 1.2;
    margin-top: 4px;
    text-transform: none;
}

span.root::before {
    display: inline;
    content: "⚠ ";
    vertical-align: middle;
}