.card_seo_slack {
    display: flex;
    font-family: 'Noto Sans', 'NotoSansJP','Slack-Lato', 'Lato','appleLogo', sans-serif;
    font-size: 15px;
    line-height: 1.46666667;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility
}

.card_seo_slack__bar {
    flex-shrink: 0;
    width: 4px;
    background-color: #e8e8e8;
    border-radius: 8px
}

.card_seo_slack__content {
    padding: 0 12px;
    max-width: 520px
}

.card_seo_slack__favicon {
    border-radius: 2px;
    box-sizing: content-box;
    height: 16px;
    margin-right: 6px;
    overflow: hidden;
    vertical-align: middle;
    width: 16px
}

.card_seo_slack__link {
    color: #1D1C1D;
    flex: 1;
    overflow: hidden;
    word-wrap: break-word;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
}

.card_seo_slack__title:hover {
    color: #0B4C8C;
    text-decoration: underline;
    cursor: pointer;
}

.card_seo_slack__title {
    color: #0576b9;
    font-weight: 700;
    font-variant-ligatures: common-ligatures
}

.card_seo_slack__description {
    color: #2c2d30
}

.card_seo_slack__image {
    border-radius: 4px;
    margin-top: 5px;
    max-width: 360px;
    cursor: zoom-in;
}

.card_seo_slack__image img {
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1);
    max-height: 250px;
}
