.card_seo_pinterest {
    color: #211922;
    width: 236px;
    column-rule-color: #211922;
    perspective-origin: 130px 166px;
    transform-origin: 130px 166px;
    caret-color: #211922;
    cursor: zoom-in;
    border: 0px none #211922;
    font: normal normal 400 normal 12px / normal "Helvetica Neue", Helvetica, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", arial, sans-serif;
    outline: #211922 none 0px;
    transition: transform 0.2s ease 0s, -webkit-transform 0.2s ease 0s
}

.card_seo_pinterest__image {
    /* position: relative; */
    width: 236px;
    border-radius: 8px;
    overflow: hidden
}

/* .card_seo_pinterest__image:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.05)
} */

.pinterest_image {
    max-width: 100%;
}

.card_seo_pinterest__content {
    display: flex;
    padding: 4px 8px
}

.card_seo_pinterest__title {
    color: #333333;
    letter-spacing: -0.4px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid #333333;
    text-size-adjust: 100%;
    word-wrap: break-word;
    column-rule-color: #333333;
    hyphens: auto;
    perspective-origin: 0px 0px;
    transform-origin: 0px 0px;
    caret-color: #333333;
    border: 0px none #333333;
    font: normal normal 700 normal 14px / 16.8px -apple-system, system-ui, "Segoe UI", "Roboto Oxygen-Sans", Ubuntu, Cantarell, "“Fira Sans”", "“Droid Sans”", "Helvetica Neue", Helvetica, "\\30D2ラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", "\\30E1イリオ", Meiryo, "ＭＳ Ｐゴシック", Arial, sans-serif;
    outline: #333333 none 0px
}

.card_seo_pinterest__dots {
    display: flex;
    margin-top: 6px
}

.card_seo_pinterest__dot {
    width: 3px;
    height: 3px;
    border-radius: 1.5px;
    background: #8e8e8e;
    margin-left: 2px
}